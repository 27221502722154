* {
  outline: none;
  box-sizing: border-box;
  :focus {
    outline: none !important;
  }
}

label {
  margin: 0;
}

html,
body {
  margin: 0;
  font-family: $ff-OpenSans;
}

a:hover {
  color: #13b68f;
}

:root {
  --sk-black: #{$sk-black};
  --sk-primary: #{$sk-red};
  --sk-primary-hover: #{$sk-primary-hover};
  --sk-primary-light: #{$sk-primary-light};
  --sk-gray: #{$sk-gray};
  --sk-dark-gray: #{$sk-dark-gray};
  --ff-OpenSans: #{$ff-OpenSans};
  --ff-sans-serif: #{$ff-sans-serif};
}

//----no select class----//
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@mixin noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

//----center----//
.center {
  display: block !important;
  text-align: -webkit-center !important;
}
.center-all {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
@mixin center-all {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
@mixin center {
  display: block !important;
  text-align: -webkit-center !important;
}

//----main page container----//
@mixin main-ctnr-padding($screen) {
  @if $screen == mobile {
    padding: 0 40px;
  } @else if $screen == smallmobile {
    padding: 0 20px;
  } @else if $screen == tablet {
    padding: 0 80px;
  } @else {
    padding: 0 135px;
  }
}
.sk-common-main-ctnr {
  @include main-ctnr-padding(pc);
}

@include media-below(lg) {
  .sk-common-main-ctnr {
    @include main-ctnr-padding(tablet);
  }
}

@include media-below(md) {
  .sk-common-main-ctnr {
    @include main-ctnr-padding(mobile);
  }
}

@include media-below(ml) {
  .sk-common-main-ctnr {
    @include main-ctnr-padding(smallmobile);
  }
}

//----blink animation----//
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* IE */
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Opera */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
